<template>
  <b-card title="Add Job Order">
    <b-col cols="4">
      <b-button variant="primary" :to="{ name: 'ongoing-job-order' }" size="sm">
        <feather-icon size="1x" icon="ArrowLeftIcon" /> Back to Ongoing Job Order
      </b-button>
    </b-col>
    <br />
    <b-col cols="12">
      <b-row>
        <b-col>
          <b-row>
            <b-col class="mb-2" cols="6">
              <b-form-group label="Select Job type">
                <b-form-select
                  id="selectJobType"
                  v-model="selectedJobType"
                  :options="jobList"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="mb-1" cols="6" style="margin-top: 25px;">
              <b-button 
                variant="primary" 
                class="float-right" 
                :disabled="selectedPicklist.length === 0"
                @click="resetTable"
              >
              Reset Filter
            </b-button>

            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="showAdvanceFilter"
              :disabled="selectedPicklist.length > 0"
            >
              Advance Filter
            </b-button>
              
            </b-col>
          </b-row>
          <!-- <p>{{ fromBacthFilter }} {{ lineItemFilter }} {{ lineDescFilter }}</p> -->
          <b-col cols="12">
            <b-row>
              <b-col cols="6">
                <b-form-group label="Filtered By:">
                  <b-form-checkbox v-model="fromBacthFilter">From Batch</b-form-checkbox>
                  <b-form-checkbox v-model="lineItemFilter">Line Item</b-form-checkbox>
                  <b-form-checkbox v-model="lineDescFilter">Item Description</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Select By SO Number">
                  <!-- <b-form-input
                    id="selectSoNumber"
                    v-model="searchSoNumber"
                    :options="soNumberList"
                  >
                  </b-form-input> -->

                  <!-- :clearSearchOnSelect="true" -->

                  <v-select
                    class="style-chooser"
                    v-model="searchSoNumber"
                    :options="soNumberList"
                    @search="ajaxSearch"
                    :clearSearchOnSelect="true"
                  ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          
          <!-- <p>{{ selectedPicklist }}</p> -->
          <!-- <h5 class="mb-2">Pick List</h5> -->

          <div class="scrollable-table">
            <b-table v-if="selectedPicklist.length == 0"
              :items="showitem(pickListItemsFromBatch)"
              :fields="fieldsPicklist"
              small
              head-variant="dark"
              responsive
              stacked="md"
              selectable
              show-empty
              @row-selected="selectedBatch"
            >
              <template v-slot:cell(button)="row">
                <b-button
                  variant="success"
                  size="sm"
                  @click="detail(row.item)"
                  style="margin-right:10px"
                  ><feather-icon size="1x" icon="EyeIcon"
                /></b-button>
              </template>
              
            </b-table>
            <b-table v-else-if="selectedPicklist.length > 0"
              :items="showitemWithBatch(pickListItemsFromBatch)"
              :fields="fieldsRestPicklist"
              small
              head-variant="dark"
              responsive
              stacked="md"
              show-empty
              @row-selected="selectedPicklistInTable"
            >
            <!-- to custom head action -->
              <template #head(action)="data">
                <div style="width: 100px">
                    {{ data.label }}
                    <b-form-checkbox
                      v-model="selectedAllPicklistWithBatch"
                    />
              </div>
              </template>

              <template v-slot:cell(action)="row">
                <b-form-checkbox 
                :value="row.item"
                v-model="selectedPicklistWithBatch"
                :disabled="disableButtonSelectPicklist"
                /> 
              </template>
              <template v-slot:cell(button)="row">
                <b-button
                  variant="success"
                  size="sm"
                  @click="detail(row.item)"
                  style="margin-right:10px"
                  ><feather-icon size="1x" icon="EyeIcon"
                /></b-button>
              </template>
            </b-table>
          </div>

        </b-col>
      </b-row>
    </b-col>
    <br />
    <b-col cols="12">      
      <b-button variant="success" class="float-right" @click="addJobOrder">
        Confirm
      </b-button>

    </b-col>
    <!-- Modal for showing Details -->
    <b-modal
      id="picklist-detail"
      v-model="showDetailModal"
      title="Pick List Detail"
      size="lg"
    >
      <b-row>
        <b-col cols="6">
          Pick List No :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.picklistNo) }}
        </b-col>
        <b-col cols="6">
          PickList Line :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.picklistLine) }}
        </b-col>
        <b-col cols="6">
          PickList Date and Time :
        </b-col>
        <b-col cols="6">
          {{ handleDate(detailModal.picklistPrintDate) }}
        </b-col>
        <b-col cols="6">
          SO Number :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.orderNumber) }}
        </b-col>
        <b-col cols="6">
          Order Date :
        </b-col>
        <b-col cols="6">
          {{ handleDate(detailModal.orderDate) }}
        </b-col>
        <b-col cols="6">
          Pick List Type :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.pickListType) }}
        </b-col>
        <b-col cols="6">
          Shipment Marking :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.shipmentMarking) }}
        </b-col>
        <b-col cols="6">
          Quantity :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.quantity) }}
        </b-col>
        <b-col cols="6">
          From Batch :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.fromBatch) }}
        </b-col>
        <b-col cols="6">
          To Batch :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.toBatch) }}
        </b-col>
        <b-col cols="6">
          Order Handler :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.orderHandler) }}
        </b-col>
        <b-col cols="6">
          Delivery Address One :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.deliveryAddressOne) }}
        </b-col>
        <b-col cols="6">
          Delivery Address Two :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.deliveryAddressTwo) }}
        </b-col>
        <b-col cols="6">
          Invoice Address One :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.invoiceAddressOne) }}
        </b-col>
        <b-col cols="6">
          Invoice Address Two :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.invoiceAddressTwo) }}
        </b-col>
        <b-col cols="6">
          Line Item :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.lineItem) }}
        </b-col>
        <b-col cols="6">
          Line Description :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.lineDescription) }}
        </b-col>
        <b-col cols="6">
          Unit :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.unit) }}
        </b-col>
        <b-col cols="6">
          Bin Location :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.binLocation) }}
        </b-col>
        <b-col cols="6">
          Stock Balance :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.stockBalance) }}
        </b-col>
        <b-col cols="6">
          Warehouse :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.warehouse) }}
        </b-col>
        <b-col cols="6">
          PDF URL :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.pdfURL) }}
        </b-col>
        <b-col cols="6">
          Cutting Number :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.cuttingNumber) }}
        </b-col>
        <b-col cols="6">
          Company Name :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.companyName) }}
        </b-col>
        <b-col cols="6">
          Customer Code :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.customerCode) }}
        </b-col>
        <b-col cols="6">
          Cable Length :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.cableLengthInfo) }}
        </b-col>
        <b-col cols="6">
          Cable Weight :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.cableWeight) }}
        </b-col>
        <b-col cols="6">
          Remark :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.remark) }}
        </b-col>
        <b-col cols="6">
          Status :
        </b-col>
        <b-col cols="6">
          {{ detailModal.props ? detailModal.props.status ? detailModal.props.status : '' : ''}}
        </b-col>
        <b-col cols="6">
          Status No :
        </b-col>
        <b-col cols="6">
          {{ detailModal.props ? detailModal.props.statusNo : ''}}
        </b-col>
        <b-col cols="6">
          Status Type :
        </b-col>
        <b-col cols="6">
          {{ handleError(detailModal.statusType) }}
        </b-col>
        <b-col cols="6">
          Created At :
        </b-col>
        <b-col cols="6">
          {{ handleDate(detailModal.createdAt) }}
        </b-col>
        <b-col cols="6">
          Updated At :
        </b-col>
        <b-col cols="6">
          {{ handleDate(detailModal.updatedAt) }}
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          size="md"
          variant="primary"
          @click="$bvModal.hide('picklist-detail')"
        >
          Close
        </b-button>
      </template>
    </b-modal>
    <!-- Modal for advance filtering -->
    <b-modal
      id="modal-advance-filter"
      hide-header-close
      ref="modal"
      v-model="advanceFitleringModal"
      title="Advance Filtering"
      size="xl"
    >
  
      <b-col class="mb-2">
      <b-form-group label="keyword" label-for="search filter">
        <b-row>
          <span class="spin" v-if="showSpinner"></span>
        </b-row>
        <b-form-input
          v-model="searchWord"
          @input="debounceSearch"
          type="search"
          placeholder="Type to Search"
          style="float: right; margin-bottom: 10px;"
        />
      </b-form-group>
    </b-col>
    <b-col class="mb-2">
      <b-form-group label="Filtered" label-for="filter-by">
        <b-form-checkbox-group
            v-model="filterOn"
            class="mt-1"
          >
            <b-form-checkbox value="orderNumber">orderNumber</b-form-checkbox>
            <b-form-checkbox value="pickListLineNumber">pickList Line</b-form-checkbox>
            <b-form-checkbox value="warehouse">warehouse</b-form-checkbox>
            <b-form-checkbox value="deliveryAddressOne">delivery Address</b-form-checkbox>
            <b-form-checkbox value="orderHandler">order Handler</b-form-checkbox>
            <b-form-checkbox value="custumerCode">custumer Code</b-form-checkbox>
            <!-- <b-form-checkbox value="lineItem">line item</b-form-checkbox>
            <b-form-checkbox value="lineDescription">line description</b-form-checkbox> -->
            <!-- <b-form-checkbox value="isActive">Active</b-form-checkbox> -->
          </b-form-checkbox-group>  
      
      </b-form-group>
    </b-col>

      <b-table
        class="scrollable-table"
        :items="showtable(pickListItemsFromBatch)"
        :fields="fieldsPicklistWithFilter"
        :filter="searchWord"
        :per-page="perPage"
        :filter-included-fields="filterOn"
        small
        head-variant="dark"
        responsive
        stacked="md"
      >
      <template v-slot:cell(customerCode)="row">
        {{row.item.customerCode}}
        </template>
        <template v-slot:cell(action)="row">
          <b-form-checkbox :value="row.item"
                  v-model="selectedPicklist"
                />
        </template>
      </b-table>
      <b-col class="mb-2" cols="6">
        <b-form-group
          label="Per page"
          label-for="pagination"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <template #modal-footer>
        <b-button size="md" variant="success" @click="save">
          Save
        </b-button>
        <b-button
          size="md"
          variant="danger"
          @click="$bvModal.hide('modal-advance-filter')"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
    <!-- Modal for confirm jobOrder -->
    <b-modal
      id="promptConfirmation"
      hide-header-close
      ref="modal"
      v-model="promptConfirmation"
      title="Confirmation Job Order"
      size="lg"
    >
      <b-table
        :items="showitem(form.item)"
        :fields="fieldsItem"
        small
        head-variant="dark"
        responsive
        stacked="md"
      >
      </b-table>
      <b-form-checkbox
        id="urgent-job-checkbox"
        class="ml-1"
        v-model="urgentJob"
        name="urgent-job-checkbox"
      >
        Urgent Job
      </b-form-checkbox>
      <template #modal-footer>
        <b-button 
          size="md" 
          variant="success" 
          :disabled="disableButton" 
          @click="confirmAddJobOrder"
        >
          Confirm Job Order
        </b-button>
        <b-button
          size="md"
          variant="danger"
          :disabled="disableButton"
          @click="cancelConfirmAddJobOrder"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { dateFormat} from "@/utils/utils.js";

export default {
  data() {
    return {
      fromBacthFilter: true,
      lineItemFilter: false,
      lineDescFilter: false,
      urgentJob: false,
      // selectedFromBatch: null,
      promptConfirmation: false,
      fromBatch: true,
      searchWord: null,
      showSpinner: false,
      form: {
        item: [],
        drum: {
          label: "",
        },
        props: {},
        picklist: [
          {
            statusCheckBox: false,
          },
        ],
      },
      items: [],
      fieldsPicklist: [
        { key: "orderNumber", label: "SO Number", width: 200 },
        { key: "pickListLineNumber", label: "Pick List Number" },
        { key: "lineItem", label: "Line item" },
        { key: "lineDescription", label: "Description" },
        { key: "quantity", label: "Quantity" },
        { key: "unit", label: "Unit" },
        { key: "fromBatch", label: "From Batch" },
        { key: "toBatch", label: "To Batch" },
        { key: "binLocation", label: "Bin Location" },
        { key: "cuttingNumber", label: "Cutting Number" },
        { key: "remark", label: "Remark" },
        { key: "action", label: "Action" },
        { key: "button", label: "More" },
      ],
      fieldsPicklistWithFilter: [
        { key: "orderNumber", label: "SO Number", width: 200 },
        { key: "pickListLineNumber", label: "Pick List Number" },
        { key: "lineItem", label: "Line item" },
        { key: "lineDescription", label: "Description" },
        { key: "quantity", label: "Quantity" },
        { key: "fromBatch", label: "From Batch" },
        { key: "warehouse", label: "Warehouse" },
        { key: "deliveryAddressOne" || "deliveryAddressTwo", label: "Delivery Address", class: "custom-width", thClass: "custom-width" },
        { key: "orderHandler", label: "Order Handler" },
        { key: "customerCode", label: "Customer Code" },
        { key: "action", label: "Action" }
      ],
      fieldsItem: [
        { key: "orderNumber", label: "SO Number" },
        { key: "fromBatch", label: "Mama Drum" },
        { key: "quantity", label: "Quantity" },
        { key: "pickListLineNumber", label: "Pick List Number" },
      ],

      fieldsRestPicklist: [
        { key: "orderNumber", label: "SO Number", width: 200 },
        { key: "pickListLineNumber", label: "Pick List Number" },
        { key: "lineItem", label: "Line item" },
        { key: "lineDescription", label: "Description" },
        { key: "quantity", label: "Quantity" },
        { key: "unit", label: "Unit" },
        { key: "fromBatch", label: "From Batch" },
        { key: "toBatch", label: "To Batch" },
        { key: "binLocation", label: "Bin Location" },
        { key: "cuttingNumber", label: "Cutting Number" },
        { key: "remark", label: "Remark" },
        { key: "action", label: "Select All", width: 400  },
        { key: "button", label: "More" },
      ],
      selectedJobType: "Cutting - 剪线",
      jobList: [ "Cutting - 剪线", "Recoil - 卷线换轴"],
      showDetailModal: false,
      detailModal: "",
      advanceFitleringModal: false,
      filterOn: [],
      perPage: 10,
      pageOptions: [10],
      selectedPicklist: [],
      selectedPicklistWithBatch: [],
      disableButton: false,

      selectedAllPicklistWithBatch: false,
      disableButtonSelectPicklist: false,

      searchSoNumber: "",
      searchSoNumber1: "",
      soNumberList: [],
    };
  },

  methods: {
    resetTable() {
      this.selectedPicklist = []
      this.selectedPicklistWithBatch = []
      this.selectedAllPicklistWithBatch = false
      this.searchSoNumber = ""
    },

    selectedPicklistInTable(items) {
      this.selectedPicklistWithBatch = items
    },

    selectedBatch(items){
      this.selectedPicklist = items
    },
    //showing table in advance fiter
    showtable(table) {
      if (this.searchWord == '' ) {
      return []
      }
      else if (this.advanceFitleringModal == true && this.searchWord != null) {

        // show modal with selected from batch and exluded selected picklist
        if (this.selectedPicklist.length != 0) {
          return table.filter((x) => x.fromBatch == this.selectedPicklist[0].fromBatch).filter(picklist => {
          return this.selectedPicklist.some((selected) => selected.id !== picklist.id)
        })
        }
        return table
      }
      return []
    },
    //if picklist has machine true, table row become red
    showitem(data) {
      if (data != undefined) {
        data.filter((item) => {
          if (item.props.isChangeMachine == true) {
            item._rowVariant = "danger";
          }
        });

        data.map((item) => {
          // (item.lineItemDescription =
          //   item.lineItem + "_" + item.lineDescription),
            (item.pickListLineNumber =
              item.picklistNo + "_" + item.picklistLine);
        })
        return data;
      }
      return [];
    },
    showitemWithBatch(data) {
      // this.searchSoNumber = ""
      if (data != undefined) {
        data.filter((item) => {
          if (item.props.isChangeMachine == true) {
            item._rowVariant = "danger";
          }
        });

        data.map((item) => {
          (item.lineItemDescription =
            item.lineItem + "_" + item.lineDescription),
            (item.pickListLineNumber =
              item.picklistNo + "_" + item.picklistLine);
        })

        if (this.selectedPicklist.length > 0) {
          if(this.searchSoNumber != "" && this.searchSoNumber != null){
            return data
          }

          if (this.fromBacthFilter) {
            return data.filter((x) => x.fromBatch == this.selectedPicklist[0].fromBatch)
          } else if (this.lineItemFilter) {
            return data.filter((x) => x.lineItem == this.selectedPicklist[0].lineItem)
          } else if (this.lineDescFilter) {
            return data.filter((x) => x.lineDescription == this.selectedPicklist[0].lineDescription)
          }
        }else {
          return data
        }
      }
      return [];
    },

    //open modal for details picklist
    detail(item) {
      this.showDetailModal = true;
      this.detailModal = item;
    },
    //create loading when type search word
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.searchWord = event;
      }, 600);
    },
    //open advance filter modal
    showAdvanceFilter() {
      // this.fromBatch = false;
      this.advanceFitleringModal = true;
      this.addEdit = "Add";

      // this.fromBacthFilter = false;
      // this.lineItemFilter = false;
      // this.lineDescFilter = false;
    },
    //close advance filtering modal
    save() {
      this.advanceFitleringModal = false;
    },
    //push picklist selected into modal confirm
    addJobOrder() {
      if (this.selectedPicklistWithBatch.length === 0) {
        this.$bvToast.toast("Please Add Pick List First", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        this.form.item = this.selectedPicklistWithBatch;
        this.promptConfirmation = true;
      }
    },
    //close modal confirm picklist 
    cancelConfirmAddJobOrder() {
      this.promptConfirmation = false;
    },
    // create job order, make it approved, and create its fetch
    confirmAddJobOrder() {
      this.disableButton = true
      var jobType = this.selectedJobType.split(' -')[0]
      var dataJO = {
        picklistLineNumbers: this.form.item.map(
          (list) => list.pickListLineNumber
        ),
        item: this.form.item.map((data) => ({
          pickList: data.id,
          quantity: data.quantity,
          orderNumber: data.pickListLineNumber,
        })),
        drumID: this.selectedPicklistWithBatch.map((x) => x.fromBatch)[0],
        binLocation: this.selectedPicklistWithBatch.map((x) => x.binLocation)[0],
        status: "Created",
        props: {
          type: jobType,
          app: this.$session.get("phibase-app"),
          status: 10,
        },
        urgentJob: this.urgentJob,
      };

      this.$store
        .dispatch("joborder/createJobOrderandFetching", dataJO)
        .then((res) => {
          this.$bvToast.toast(res, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          setTimeout(() => {
            this.$router.push("/ongoing-job-order");
            this.disableButton = false
          }, 1000);
        })
        .catch((err) => {
          this.$bvToast.toast("From Batch mismatch. All picklists must have the same fromBatch.", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false
        })
    },
    handleError(data) {
      if (!data || "") {
        return 'no data'
      }
      return data
    },
    handleDate(data) {
      if (dateFormat(data) == "Invalid Date") {
        return "no date"
      }
      return dateFormat(data)
    },
    ajaxSearch(search, loading) {
      loading(true);
        if (search !== "") {
          // mengambil keyword yang di input
          var orderNumberPicklist = this.pickListItemsFromBatch.map(x => x.orderNumber)
          // menampilkan seluruh data order number berdasarkan keyword yang di input
          let uniqueData = orderNumberPicklist.filter((value, index, self) => self.indexOf(value) === index);
          this.soNumberList = uniqueData.filter(orderNumber => orderNumber.includes(search));

          this.soNumberList.sort((a, b) => a.localeCompare(b));
        }

        // if (item.item.status === "") {
        //   item.price = parseFloat(item.price.$numberDecimal);
        //   return {
        //     label: ${item.item.itemModel} - ${item.item.description},
        //     description: item.item.description,
        //     itemModel: item.item.itemModel,
        //     ...item,
        //   };
        // }
      loading(false);
    }
  },

  created() {
    document.title = "Add Job Order | Tai Sin - Commerce";
  },
  computed: {
    //get all picklist
    pickListItemsFromBatch() {
      let allpicklist = this.$store.getters["picklist/getPickablePicklist"];
      let includedPicklist = allpicklist.filter(
        (picklist) =>
          picklist.props.status == "pickable" &&
          picklist.pickListType != "S"
      );

      if(this.searchSoNumber != "" && this.searchSoNumber != null){
        // this.fromBacthFilter = false
        // this.lineItemFilter = false
        // this.lineDescFilter = false

        let finalPicklist = includedPicklist.filter(
          (picklist) =>
          picklist.orderNumber == this.searchSoNumber
        );
        

        return finalPicklist;
      }

      if(this.searchSoNumber == null){
        this.fromBacthFilter = true
        this.selectedPicklist = []
      }

      if (this.selectedJobType == 'Cutting - 剪线') {
        let finalPicklist = includedPicklist.filter(
          (data) => data.pickListType == 'C'
        );
        return finalPicklist;
      }

      if (this.selectedJobType == 'Recoil - 卷线换轴') {
        let finalPicklist = includedPicklist.filter(
          (data) => data.pickListType == 'R'
        );
        return finalPicklist;
        
      }

      return []

      // if (this.selectedJobType == 'Direct Sale - 直销') {
      //   let finalPicklist = includedPicklist.filter(
      //     (data) => data.pickListType == 'C' && data.stockBalance == 0
      //   );
      //   return finalPicklist;
      // }
    },
  },
  watch: {
    // make advance filter can only select one picklist
    selectedPicklist(item) {
      if (this.advanceFitleringModal == true) {
        this.advanceFitleringModal = false
      }
    },

    selectedJobType(item) {
      this.selectedPicklist = []
      this.selectedPicklistWithBatch = []
    },

    fromBacthFilter(value) {
      if (value) {
        this.lineItemFilter = false,
        this.lineDescFilter = false
      } else {
        this.selectedPicklist = []
        this.selectedPicklistWithBatch = []
      }
    },

    lineItemFilter(value) {
      if (value) {
        this.fromBacthFilter = false,
        this.lineDescFilter = false
      } else {
        this.selectedPicklist = []
        this.selectedPicklistWithBatch = []
      }
    },

    lineDescFilter(value) {
      if (value) {
        this.fromBacthFilter = false,
        this.lineItemFilter = false
      } else {
        this.selectedPicklist = []
        this.selectedPicklistWithBatch = []
      }
    },

    selectedAllPicklistWithBatch(value) {
      if (value) {
        this.disableButtonSelectPicklist = true
        this.selectedPicklistWithBatch = this.showitemWithBatch(this.pickListItemsFromBatch)
      } else {
        this.disableButtonSelectPicklist = false
        this.selectedPicklistWithBatch = []
      }
    },
  },
  
  mounted() {
    this.$store.dispatch("picklist/getPickablePicklist")
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
  appearance: textfield;
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
.scrollable-table {
  overflow-x: auto;
  width: 100%;
}

.custom-width {
  width: 5000px;
}
</style>
